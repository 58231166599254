<script>
import ClienteMixin from "components/clientes/components/cliente/include/ClienteMixin"
//import ClienteFinanceiroList from "components/clientes/components/cliente/include/Financeiro"
import ClienteFinanceiroList from "components/financeiro/components/Contas.vue"
import ERow from "components/layout/components/Row.vue";
import TaskDetail from "components/suporte/cliente/components/include/TaskDetail.vue";
import ECol from "components/layout/components/Col.vue";

export default {
  name: 'ClienteFinanceiro',
  components: {ECol, TaskDetail, ERow, ClienteFinanceiroList},
  mixins: [ClienteMixin]
}
</script>

<template>
  <div class="scliente-page-pad suporte-cliente-tarefas cli-task-page">
    <div class="case">
    <div class="case-tab">
      <div class="tab-header">
        <e-row class="full-width">
          <e-col class="m-b-none">Estatísticas</e-col>
          <e-col class="text-right m-b-none flex items-center justify-end">
          </e-col>
        </e-row>
      </div>
      <div class="tab-content starefa-page">
        <e-row class="def full-width" mr>
          <e-col>
            <e-row mr>
              <e-col>
                <task-detail title="Leilões">
                  {{cliente.statLeiloes|number}}
                </task-detail>
              </e-col>
              <e-col>
                <task-detail title="Bens">
                  {{cliente.statBens|number}}
                </task-detail>
              </e-col>
            </e-row><e-row mr>
            <e-col>
              <task-detail title="Base Cadastral">
                {{cliente.statClientes|number}}
              </task-detail>
            </e-col>
            <e-col>
              <task-detail title="Vendas">
                {{cliente.statVendas|number}}
              </task-detail>
            </e-col>
          </e-row>
          </e-col>
          <e-col>
            <e-row mr>
              <e-col>
                <task-detail title="Ticket In">
                  {{cliente.ticketIn|number}}
                </task-detail>
              </e-col>
              <e-col>
                <task-detail title="Ticket Out">
                  {{cliente.ticketOut|number}}
                </task-detail>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
    </div>
    </div>
    <cliente-financeiro-list class="m-t fin-cliente" :pessoa="cliente.pessoa" no-date-filter ocultar-resumo :parse-props="{embedComponent: true}" />
  </div>
</template>
